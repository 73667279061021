.form-group {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0px;
}

.form-horizontal .control-label-disabled {
  padding-top: 0px;
  margin-top: 0;
  margin-bottom: 0;
  /*text-align: right;*/
  /* new 14.04.2017 */
  text-align: left;
  margin-left: 20px;
}

/* new 14.04.2017 */
@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: left;
        margin-left: 20px;
    }
}

.col-md-mandatory {
  width: 1ex;
  margin-right: -28px;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px !important;
  }
  
  .col-md-mandatory-17 {
  width: 1ex;
  margin-left: -17px !important; /* only difference with col-md-mandatory */
  margin-right: -28px;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px !important;
  }
  
  .col-md-mandatory-14 {
  width: 1ex;
  margin-left: -14px !important; /* only difference with col-md-mandatory */
  margin-right: -28px;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px !important;
  }
  
  .col-md-mandatory-5 {
  width: 1ex;
  margin-left: 5px !important; /* only difference with col-md-mandatory */
  margin-right: -28px;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2px !important;
  }
  
  .col-md-1 {
  width: 5%;
  padding-right: 0px;
  }
  .col-md-2 {
  width: 10%;
  padding-right: 0px;
  }
  
  .col-md-3 {
  width: 15%;
  padding-right: 0px;
  }
  
  .col-md-4 {
  width: 20%;
  padding-right: 0px;
  }
  
  .col-md-5 {
  width: 25%;
  padding-right: 0px;
  }
  
  .col-md-6 {
  width: 30%;
  padding-right: 0px;
  }
  
  .col-md-7 {
  width: 35%;
  padding-right: 0px;
  }
  
  .col-md-8 {
  width: 40%;
  padding-right: 0px;
  }
  
  .col-md-9 {
  width: 45%;
  padding-right: 0px;
  }
  
  .col-md-95 {
      width: 47.5%;
      padding-right: 0px;
      position:relative;
      float:left;
      padding-left:15px;
  }
  
  .col-md-96 {
      width: 49.5%;
      padding-right: 0px;
      position: relative;
      float: left;
      padding-left: 15px;
  }
  
  .col-md-10 {
  width: 50%;
  padding-right: 0px;
  }
  
  .col-md-11 {
  width: 55%;
  padding-right: 0px;
  }
  
  .col-md-12 {
  width: 60%;
  padding-right: 0px;
  }
  
  .col-md-125 {
      width: 73%;
      padding-right: 0px;
      position: relative;
      float: left;
      padding-left: 32px;
  }
  
  .col-md-13 {
  width: 65%;
  padding-right: 0px;
  }
  
  .col-md-14 {
  width: 70%;
  padding-right: 0px;
  }
  
  .col-md-15 {
  width: 75%;
  padding-right: 0px;
  }
  
  .col-md-16 {
  width: 80%;
  padding-right: 0px;
  }
  
  .col-md-17 {
  width: 85%;
  padding-right: 0px;
  }
  
  .col-md-171 {
      width: 85.5%;
      padding-right: 0px;
  }
  
  .col-md-172 {
      width: 86%;
      padding-right: 0px;
  }
  
  .col-md-175 {
  width: 87.5%;
  padding-right: 0px;
  }
  
  .col-md-18 {
  width: 90%;
  padding-right: 0px;
  }
  
  .col-md-181 {
      width: 90.5%;
      padding-right: 0px;
  }
  
  .col-md-185 {
      width: 90.5%;
      padding-right: 0px;
      /* padding left to align with other titles */
      padding-left: 15px;
  }
  
  .col-md-186 {
      width: 91.5%;
      padding-right: 0px;
  }
  
  .col-md-188 {
      width: 93%;
      padding-right: 0px;
  }
  
  .col-md-19 {
  width: 95%;
  padding-right: 0px;
  }
  
  .col-md-191 {
      width: 96%;
      padding-right: 0px;
  }
  
  .col-md-192 {
      width: 97%;
      padding-right: 0px;
  }
  
  .col-md-195 {
  width: 97.5%;
  padding-right: 0px;
  }
  
  .col-md-20 {
  width: 100%;
  padding-right: 0px;
  }
  
  .col-md-200 {
      width: 100%;
      padding-right: 0px;
      /* padding left to align with other titles */
      padding-left: 15px;
      /* pour les cas avec des caractères spéciaux (flèches par exemple) */
      height: 35px;
  }
  
  .col-md-number-box-unit {
      /*width: 50%;*/
      margin-left: 0px !important;
      padding-right: 0px;
      padding-left: 7px;
      text-align: left !important;
      font-weight: normal !important;
      float: left;
      color: #7f7f7f !important;
  }
  
  .col-md-number-box-unit_B02 {
      margin-left: 0px !important;
      padding-right: 0px;
      padding-left: 7px;
      text-align: left !important;
      font-weight: normal !important;
      color: #7f7f7f !important;
  }
  
  .col-md-number-box-unit_B08 {
      margin-left: 0px !important;
      padding-right: 0px;
      padding-left: 7px;
      text-align: left !important;
      font-weight: normal !important;
      color: #7f7f7f !important;
  }

  .var-box-form-control {
    float: left;
    display: block;
    width: 24%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.var-box-form-control:hover {
    background-color: #C5E8FF;
}

.var-box-form-control:focus {
    background-color: #C5E8FF;
}

/* 05.06.2020 */
.var-box-form-control[disabled],
.var-box-form-control[readonly],
fieldset[disabled] .var-box-form-control {
    cursor: not-allowed;
    background-color: #ffffff;
}

#containerB08 {
    position: relative;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: auto;
    font-size: 13px;
  }
  
  #colB08 {
    display: inline-table;
    font-size: 15px;
    vertical-align: top;
    margin-left: -2px;
    margin-right: -2px;
  }